<template>
  <div class="form-check form-switch m-0">
    <input
      @input="setValue($event)"
      :checked="rawValue"
      class="form-check-input"
      type="checkbox"
      id="calendar-view-mode"
    >
    <label class="form-check-label" for="calendar-view-mode">
      Compact view
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CalendarViewModeConfig',
  computed: {
    ...mapState('config', ['configUICalendarViewMode']),
    rawValue() {
      return this.configUICalendarViewMode === 'compact';
    },
  },
  methods: {
    setValue(e) {
      const value = e.target.checked ? 'compact' : 'full';
      this.$store.dispatch('config/setUICalendarViewMode', {
        vm: this,
        value,
      });
    },
  },
};
</script>
